 
/* For bigscreen View */
@media screen and (min-width: 1500px) {
    .gr-buttons {
        margin-bottom: -55px;
          margin-left: 10px;
          text-align: center;
          padding-top: 50px;
      }
}

/* For Desktop View */
@media screen and (min-width: 1240px) and (max-width :1499px) {
    .gr-buttons{
        margin-bottom: -55px;
        margin-left: 80px;
        text-align: left;
        padding-top: 50px;
    
    }
    .deznav {
      width: 18.563rem !important;
    }
    .gr-buttons.boe-buttons {
        margin-left: 180px;
    }
    .header {
        padding-left: 17rem !important;
    }
}
@media only screen and (max-width: 1239px){
    .gr-buttons{
        margin-bottom: 20px;
        margin-right: 2%;
        text-align: right;
        padding-top: 50px;
    }
    .deznav{
        width: 16rem !important;
    }
    .header {
        padding-left: 17rem !important;
    }
    #main-wrapper .deznav .deznav-scroll {
        overflow: hidden !important;
    }
}
@media only screen and (max-width: 768px){
    .header {
        padding-left: 0rem !important;
    }
    .header .header-content {
        
        padding-left: 0 !important;
    }
}
 
/* For Tablet View */
@media screen and (min-device-width: 768px)  and (max-device-width: 1024px) {

     .gr-buttons{
        margin-left: 75px;
        text-align: left;
        padding-top: 50px;
    
    }
    .header {
        padding-left: 17rem !important;
    }
    .deznav {
      width: 18.563rem !important;
    }
    .gr-buttons.boe-buttons{
      text-align: left !important;
      margin-bottom: 20px !important;
      margin-left: 2%;
    }
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)  and (orientation: portrait) {
   
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)  and (orientation: landscape) {
    
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen  and (max-device-width: 640px) {
    
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)  and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px)   and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    
};