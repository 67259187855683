@font-face {
    font-family: 'Avenir-Black';
    src: url('../../../images/custom/avenir_ff/AvenirLTStd-Black.otf');
  }

  @font-face {
    font-family: 'Avenir-Roman';
    src: url('../../../images/custom/avenir_ff/AvenirLTStd-Roman.otf');
  }
body{
    background-color: #F9F5F5    !important;
}

.deznav{
    background: #fff !important;
    color: #000 !important;
    padding-top: 50px !important;
    box-shadow: 0px 4px 50px 0px #B9B9B9 !important;
    width: 280px !important;
    text-align: center;
}
.header{
    background-color: #ffffff !important;
    box-shadow: 0px 4px 50px 0px #B9B9B9 !important;
}
.content-body {
    margin-left: 17rem !important;
}
.sidelogo{
    max-width: 140px;
}
.admin-name {
    background: #26A69A;
    cursor: pointer;
    color: #fff;
    padding: 20px;
    border-radius: 50%;
    border: 3px solid #384957ad;
    line-height: 0px;
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
}
.header-title{
    margin-left: 60px;
}
.header-title .menu-name{
    color: #2C3175;

/* bold/16 */
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
margin-left: 20px;
}
.btn-apl{
    background-color:#C2612C !important;
    border-radius:10px !important;
    width:100%;
    border-color: #C2612C !important;
    color: #fff !important;
}
.btn-apl:hover{
    background-color: #fff !important;
    color: #C2612C !important;

}
.dash-form {
    border-radius: 10px;
    background: #2C3175;
    box-shadow: 0px 4px 50px 0px #DADADA;
    padding: 50px;
}
.dash-form .form-control {
    background: #fff;
    border: 1px solid #fff;
    color: #fff;
    height: 56px;
    background: #2c3175 ;
}
.report-card {
    border-radius: 10px;
    border: 1px solid #CACACA;
    background: #f0f0f0;
    box-shadow: 0px 4px 50px 0px rgba(185, 185, 185, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    margin-bottom: 12px;
    align-items: center;
}
.report-value {
    color: #1E1E1E;
    font-family: 'Avenir-Black';
    font-size: 19.2px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.report-label {
    color: #1E1E1E;
    font-family: 'Avenir-Roman';
    font-size: 19.2px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.report-value.box {
    background: #1E1E1E;
    font-size: 16px;
    display: flex;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
[data-sidebar-style="overlay"] .content-body {
    margin-left: 0 !important;
}
[data-sidebar-style="overlay"] .deznav {
    width: 10rem !important;
}

@media only screen and (max-width: 1199px){
    .deznav {
        width: 10rem !important;
    }
}

/* [data-sidebar-style="mini"] .deznav {
    width: 5rem !important;
} */


ul.my-menu {
    padding-left: 40px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    margin-bottom: 40px !important;
    text-align: left;
}
.my-menu .main-item{
    margin-bottom: 40px;
    cursor: pointer;
}
.my-menu .main-item .menu-link i {
    font-size: 20px;
    font-weight: 500;
    margin-right: 15px;
}

.my-menu .main-item .menu-link {
    border-radius: 10px;
    background: #EAEAEA;
    padding: 13px 20px;
    width: 200px;
    display: flex;
    color: #1E1E1E;
    font-family: 'Avenir-Black';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-items: center;
    justify-content: space-evenly;
}

.my-menu .main-item .sub-menu-item .sub-item::before {
    content: "";
    height: 56px;
    width: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    background: #EAEAEA;
    /* background:  #C2612C; */
    border-radius: 13px;
    transition: all .5s;
}
.my-menu .main-item .sub-menu-item .sub-item .sub-link.active::before{
    content: '';
    background: #fff !important;
}
.my-menu .main-item .sub-menu-item .sub-item .sub-link.active{
    
    color: #C2612C !important;
}
.my-menu .main-item .sub-menu-item .sub-item .sub-link::before{
    content: '';
    /* background: #1E1E1E; */
    background: #D9D9D9;
    left: -2.5px;
    top: 10px;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    position: absolute;
}
.my-menu .main-item .sub-menu-item .sub-item{
    position: relative;
    padding: 10px 0;

}
.my-menu .main-item .sub-menu-item .sub-item .sub-link{

    color: #1E1E1E;

/* Body/Roman */
font-family: 'Avenir-Roman';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: relative;
/* padding-left: 40px; */
padding: 7px 40px;
    width: 100%;
    display: list-item;

}
ul.sub-menu-item{
    padding-left: 25px !important;
    display: none;

}
.my-menu .main-item.expand ul.sub-menu-item {
    
    display: block !important;
}
/* .my-menu .main-item.active ul.sub-menu-item {
    
    display: block !important;
} */

.my-menu .main-item.active .menu-link {
    
    background: #2C3175;
    color: #fff !important;
}

.my-menu .main-item.active .sub-menu-item .sub-item::before {
    background:  #C2612C;
}

.my-menu .main-item.active .sub-menu-item .sub-item .sub-link::before {
    background:  #C2612C;
}

.my-menu .main-item .menu-link:hover {
    
    background: #2C3175;
    color: #fff !important;
}

.my-menu .main-item.expand .sub-menu-item:hover .sub-item::before {
    background:  #C2612C;
}

.my-menu .main-item.expand .sub-menu-item:hover .sub-item .sub-link::before {
    background:  #C2612C;
}

.my-menu .main-item .sub-menu-item .sub-item .sub-link:hover::before{
    content: '';
    background: #fff !important;
}
.my-menu .main-item .sub-menu-item .sub-item .sub-link:hover{
    
    color: #C2612C !important;
}



/* .my-menu .main-item.active .menu-link .fa-chevron-down:before {
    content: "\f077";
} */

.my-menu .main-item.expand .menu-link .fa-chevron-down:before {
    content: "\f077";
}

.my-menu .main-item:hover  .menu-link{
    
    background: #2C3175;
    color: #fff !important;
}

.aprl tbody tr td {
    color: #000 !important;
    font-family: 'Avenir-Roman';
  }

  .dash-form .dx-texteditor-input-container .dx-texteditor-input {
    color: #ffff;
    border: none !important; 
  }

  .dash-form  .dx-button-content .dx-dropdowneditor-icon{
    color: #fff !important;
  }
  .dash-form .dx-texteditor-input-container .dx-widget.dx-button-mode-contained.dx-button-normal.dx-dropdowneditor-button{
    background: transparent;
  }
  .dash-form .dx-texteditor-container {
    border: 1px solid #fff;
    border-radius: 5px;
  }
 
  .dash-form #leasetype{
    border-right: 16px solid transparent;
    outline: 1px solid;
    border-color: transparent;
    width: 99%;
  }
  .repo-from #leasetype{
    border-right: 16px solid transparent;
    outline: 1px solid transparent;
    border-color: transparent;
    width: 99%;
    color: #000;
    font-size: medium;
  }
  .repo-from #leasetype:focus{
    outline: 1px solid !important;
  }
  .loderdiv{
    text-align: center;
  }
  .file-td{
    width: 314px;
    color: #000;
    font-family: 'Avenir-Roman';
  }
  .delete-buttons{
    
    color: #cd3939;
    font-family: 'Avenir-Roman';
  }
  .vie-buttons{
    color: #469a0e;
    font-family: 'Avenir-Roman';
    cursor: pointer;
  }
  .file-header-label{
    color: #2C3175;

    /* sh3/Heavy */
    font-family: 'Avenir-Roman';
    font-size: 19.2px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 25px;
    padding-top: 20px;
  }
  .file-image-zone .dzu-dropzone{
    overflow-x: hidden;
    border-radius: 20px;
    border: 2px dashed #2C3175;
    background: #F7F8FF;
    overflow-y: auto;
    min-height: 300px;
  }

  .file-doc-zone .dzu-dropzone{
    overflow-x: hidden;
    border-radius: 20px;
    border: 2px dashed #2C3175;
    background: #F7F8FF;
    overflow-y: auto;
  }
  .file-inner-text{
    color: #000;
    /* Body/Heavy */
    font-family: 'Avenir-Black';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .file-click-text{
    color: #2C3175;
    border-bottom: 1px solid #2C3175;
  }
  label.dzu-inputLabelWithFiles {
    background: #2c3175;
    color: #fff;
    padding: 8px 40px;
    font-family: 'Avenir';
}

.ps__rail-y:hover>.ps__thumb-y, .ps__thumb-y {
    background-color: #757575 !important;
    width: 6px !important;
}