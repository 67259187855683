.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}
.map-container {
  height: 100%;
  width: 100%;
}
.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
.expandView {
  position: fixed;
  z-index: 2000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0 0 0 / 75%);
}
.expandCl{
  position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    transition: 0.3s;
    text-decoration: none;
}
.expandCl:hover, .expandCl:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.expandImg img {
  -webkit-animation-name: zoomc;
  -webkit-animation-duration: 0.6s;
  animation-name: zoomc;
  animation-duration: 0.6s;
}
.expandImg img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 900px;
}
.dx-toolbar-item-content .dx-datagrid-addrow-button .dx-icon-edit-button-addrow{
  color: transparent !important;
}

/* Add Animation */
.expandView img {  
  -webkit-animation-name: zoomc;
  -webkit-animation-duration: 0.6s;
  animation-name: zoomc;
  animation-duration: 0.6s;
}

li.main-files{
  color: #000;
  font-size: large;
  padding: 10px 0 12px 20px;
}
li.sub-files{
  padding: 10px;

}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.sub-files:before{
  content: "◆";
  margin-right: 10px;
}
.export-buttons,.delete-buttons{
  cursor: pointer;
}
.export-buttons:hover{
  color: rgb(14, 111, 14);
}
.delete-buttons:hover{
  color: red;
}

@-webkit-keyframes zoomc {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoomc {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}
/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .expandView {
    width: 100%;
  }
}
.dx-datagrid .dx-link.dx-icon-edit{
  color: #00A0D3;
}
.dx-datagrid .dx-link.dx-icon-trash{
  color: #C42929;
}

.dx-datagrid-headers {
  background-color: #2C3175 !important;
  color: #fff !important;
}

.dx-datagrid-headers td.dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled,.dx-datagrid-headers td.dx-cell-focus-disabled.dx-datagrid-drag-action,.dx-datagrid-headers td.dx-command-expand.dx-datagrid-group-space.dx-cell-focus-disabled {
  background: #2C3175;
}

.per-card .dx-toolbar-item-content .dx-datagrid-addrow-button .dx-icon-edit-button-addrow{
  color : #000 !important
}

.per-card button.dx-button-mode-text.dx-button-normal.dx-button-has-text.dx-button-has-icon.dx-datagrid-toolbar-button.dx-edit-button.dx-datagrid-addrow-button {
  background-color: #e0e0e0 ;
}

.btn-year{
  color: #fff !important;
  border-radius: 10px !important;
  background: #C2612C !important;
  padding: 0.65rem 1rem !important;
  margin-right: 32px;
  z-index: 2;
    position: relative;
}

a.btn.btn-add {
  color: #fff;
  border-radius: 10px;
  background: #26975A;
  padding: 0.625rem 1rem;
  z-index: 2;
    position: relative;
}
a.btn.btn-add svg {
  margin-left: 20px;
}
.btn-add.cust-add{
 margin-left: 120px;
}

.dx-datagrid-group-panel .dx-group-panel-item{
  border-radius: 10px !important;
background: #C42929 !important;
color: #fff !important;
padding: 15px;
width: 150px;
font-size: 16px;
}

.dx-datagrid-group-panel .dx-sort{
  color: #fff !important;
  margin-left: 20px !important;
  font-size: large;
}
.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-addrow-button .dx-icon-edit-button-addrow{
  color: #ffff !important;
}

.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-addrow-button{
  border-radius: 10px;
  background-color: #26975A;
  color: #ffff;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
}

.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-addrow-button:before {
  content: "Add New ";
  display: flex;
  align-items: center;
}
.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-export-button .dx-icon-xlsxfile{
  color: #ffff;
}
.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-export-button{
  border-radius: 10px;
  /* background: #2D4875; */
  background-color: #673AB7;
  color: #ffff;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
}

.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button.dx-button-has-icon.dx-button-has-text.dx-datagrid-export-button:before {
  content: "Export Data";
  display: flex;
  align-items: center;
}

.rep-title{
  color: #000;

/* sh1/Heavy */
font-family: 'Avenir-Black';
font-size: 27.65px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.btn-apply{
  background-color: #C2612C !important;
    border-radius: 10px !important;
    width: 100%;
    border-color: #C2612C !important;
    color: #fff !important;
  padding: 11px;
  display: flex;
    justify-content: center;
    align-items: center;
    border: none;

}
a.btn.btn-export:hover {
  background-color: #ffff;
  color: #26975A;
}

a.btn.btn-export {
  color: #ffff;
  border-radius: 10px;
  background: #26975A;
  padding: 11px;
  display: flex;
    justify-content: center;
    align-items: center;
}
.modal-title{
  color: #2C3175 !important;

/* sh3/Heavy */
font-family: 'Avenir-Black';
font-size: 19.2px !important;
font-style: normal;
font-weight: 800 !important; 
line-height: normal !important;
}

.modal-header {
  border-bottom: 1px solid #B9B9B9 !important;
}
#commentModel .modal-header {
  border-bottom: 0px !important;
}
.modal-footer .btn.btn-primary{
  border-radius: 10px;
background: #C2612C !important;
border-color: #C2612C !important;

/* Body-sm/Medium */
font-family: 'Avenir-Black';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.modal-footer .btn.bg-success.btn.btn-primary{
  background: #37D159 !important;
border-color: #37D159 !important;
}
.modal-footer .btn.bg-danger.btn.btn-primary{
  background: #FF6746 !important;
border-color: #FF6746 !important;
}

.dx-field-item-label-text{
 
color: #000 !important;
font-family: 'Avenir-Black' !important;
font-size: 14px !important;
font-style: normal;
font-weight: 800 !important;
line-height: normal !important;
}
.dx-texteditor-input{
  border-radius: 5px;
border: 1px solid #B9B9B9;
}
.dx-datagrid-search-panel .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-input{
  border-radius: 10px !important; 
border: 1px solid #E6E6E6 !important;
background: #E6E6E6;
}

.dx-datagrid-search-panel{
  width: 250px !important;
}
.dx-card,.dx-datagrid,.dx-datagrid-header-panel,.dx-toolbar{
  background-color: transparent !important;
  box-shadow: none;
}
.dx-datagrid-rowsview{
  background-color: #fff !important;
}
.dx-toolbar-items-container{
  margin-bottom: 30px !important;
}
/* .dx-datagrid-headers{
  border-radius: 10px !important;
} */

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {

  text-align: center !important;
  vertical-align: middle;
}


@media only screen and (max-width: 1200px){
  
}
@media only screen and (min-width: 1400px){
  
}


.aprl thead th {
  color: #fff;
  background: #2c3175;
}
.table-responsive .aprl {
  margin-bottom: 0;
  color: #000;
  font-family: 'AVENIR';
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content{
  color: #ffffffde !important;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) {
  background-color: #673ab7 !important;
}
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover{
  height: 15px !important;
  cursor: pointer !important;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td{
  cursor: zoom-in ;
}
 .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td{
  cursor: pointer !important ;
}

.info-row {
  display: flex;
  margin-bottom: 20px;
  padding-left: 20px;
}
.info-label {
  color: #1E1E1E;
  font-family: 'Avenir-Roman';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.info-value {
  font-family: 'AVENIR';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: #1E1E1E;
  padding-left: 10px;
}
.info-label svg {
  margin-right: 10px;
}
.info-title {
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #E4E4E4;
  background: #2C3175;
  box-shadow: 0px 4px 50px 0px rgba(28, 28, 28, 0.15);
  color: #FFF;
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 12px;
}
.info-col {
  width: 20%;
  display: flex;
}
.info-svg {
  margin-right: 5px;
}
.info-record-label {
  color: #000;
  font-family: 'Avenir-Roman';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}
.info-record-value {
  color: #000;
  font-family: 'AVENIR';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.info-details {
  border-radius: 0 0 10px 10px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(28, 28, 28, 0.15);
  display: flex;
  padding: 20px 10px;
}

.repo-form  .dx-button-content .dx-dropdowneditor-icon{
  color: #000 !important;
}
.repo-from .dx-texteditor-container {
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 5px;
}
.repo-form .dx-texteditor-input-container input.dx-texteditor-input {
  border: none !important;
}

.dx-datebox .dx-dropdowneditor-input-wrapper .dx-texteditor-input-container input.dx-texteditor-input {
  border: none !important;
}
.aprl tfoot tr th {
  font-size: large;
}
.aprl tfoot{
  background-color: #fff;
}

.ctab.nav-tabs .nav-link.active {
  color: #fff;
  background: #2c3174;
}

.ctab.nav-tabs .nav-link {
  border-radius: 10px 10px 0 0;
  margin-right: 20px;
  color: #000;
  font-weight: bold;
  font-family: 'Avenir';
}
.ctab.nav-tabs .nav-link:hover {
  color: #2c3174;
  background: azure;
}
.snaptable{
  border-top: 3px solid #2c3174;
    margin-top: 20px;
    padding-top: 10px;
}
.dx-popup-normal .dx-scrollable-scroll.dx-state-invisible{
	background-color :#ababab !important;
}


.dx-popup-normal .dx-scrollable-scroll-content{
	background-color: #ababab !important;
	width: 16px !important;
}
.dx-popup-normal .dx-scrollbar-vertical.dx-scrollbar-hoverable {
    width: 16px !important;
    }
.dx-popup-normal .dx-scrollbar-vertical .dx-scrollable-scroll {
    padding:0px !important;
    width: 13px !important;
}

.lease-details .modal-dialog-scrollable .modal-body {
  overflow-x: hidden;
}