@font-face {
    font-family: 'Avenir';
    src: url('../../images/custom/avenir_ff/AvenirLTStd-Black.otf');
  }

.map-bdy{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #3a3e75a6; */
}
.text-danger.reg-error {
    padding: 10px;
}
.backimage{
    background-repeat: no-repeat;
    background-size: cover;
    padding: 140px 100px;

}
.form-control {
    border-radius: 5px !important;
}
.sign-head{
    color: #fff;
    font-family: 'Avenir';
    font-size: 47.78px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.956px;
}

.auth-form{
    background-color: #2C3175;
    padding: 80px 140px !important;
}
.auth-labels{
    color: #fff;
    margin-left: 20px;
    font-family: 'Avenir';
}
.forgot-labels{
    color: #fff;
    margin-left: 20px;
    font-family: 'Avenir';
}
.btn-sign {
    color: #fff;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    -webkit-user-select: none;
    user-select: none;
    padding: 15px;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 5px;
    background-color: #C2612C;
    border-color: #C2612C;
}
.btn-sign:hover{
    background-color: #fff;
    color: #C2612C;
}
.text-sign{
    color: #C2612C;
}
.sign-account{
    color: #fff;
    text-align: center;
}
.text-sign:hover{
    color: #f5eeeb;
}
.forgot-labels:hover{
    color: #C2612C;
}
.larplogo{
    max-width: 180px;
}
.seallogo{
    max-width: 60px;
}
#field-icon {
    float: right;
    margin-top: -35px;
    position: relative;
    z-index: 2;
    margin-right: 20px;
    font-size: 18px;
}